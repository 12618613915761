import { Text } from "@nju/ui";
import { Icon, IconName } from "@nju/icons";
import { cx } from "@nju/css";

export interface ErrorMessageProps {
  message: string;
  withIcon?: boolean;
  iconAlign?: "start" | "center";
  testId?: string;
  icon?: IconName;
}

export function ErrorMessage({
  message,
  withIcon = true,
  iconAlign = "center",
  icon = "exclamation-circle",
  testId,
}: ErrorMessageProps) {
  return (
    <div
      className={cx("flex", {
        "from-tablet:items-start": iconAlign === "start",
        "from-tablet:items-center": iconAlign === "center",
      })}
      data-cy={testId}
    >
      {withIcon && (
        <Icon className="shrink-0 mr-2 w-4 h-4" name={icon} tone="alert" />
      )}
      <Text
        size={{
          "@onlyMobile": "s",
        }}
        tone="alert"
      >
        {message}
      </Text>
    </div>
  );
}
